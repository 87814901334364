import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

const SuccessPage = () => (
  <Layout>
    <SEO title="Success!" />
    <section className="flex center column">
      <h1 className="text-primary huge">Success!</h1>
      <h3 className="text-disabled">Your message has been sent successfully. I'll get right back to you.</h3>
    </section>
  </Layout>
)

export default SuccessPage
